@import '../../common/colors.scss';

@font-face {
  font-family: 'Honk';
  src: url('../../common/Honk-Regular-VariableFont_MORF,SHLN.woff2') format('woff2');
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.nav-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.2rem;
  background-color: $primary-background-color;
}

.nav-item {
  font-family: 'Honk';
  text-decoration: none;
  color: $black-text-color;
  border-width: 4px;
  border-style: solid;
  border-color: $thirdary-text-color;
  padding-left: 15px;
  padding-right: 15px;
  background-color: $secondary-text-color;
  font-size: 3rem;
  transition: color 0.2s ease;
  animation: blink 1s infinite alternate;
  cursor: pointer;
}

.nav-item:not(:last-child) {
  margin-right: 100px;
}

/* Example blinking text animation */


/* Mobile adjustments */
@media screen and (max-width: 768px) {
  .nav-item {
    margin: 0.2rem 0;
    font-size: 2rem;
    padding-left: 5px;
    padding-right: 5px;
    animation: none;
  }

  .nav-item:not(:last-child) {
    margin-right: 10px;
  }
}