@import '../../common/colors.scss';

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: $primary-background-color;
}

.home-body {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: $primary-background-color;
  width: 100%;
}

.home-team-images {
  margin-top: 2vh;
  margin-bottom: 2vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 33%;
}

.home-team-image-logo {
  height: calc(5px + 10vw);
  width: calc(5px + 10vw);
  padding-top: 7%;
  padding-bottom: 7%;
}

.home-team-poster {
  width: 40%;
}

.home-teaser-text {
  color: $black-text-color;
  font-size: calc(5px + 2vmin);
  width: 40%;
  text-align: center;
  margin: 0;
}

.home-body-mobile-images {
  display: none;
}

/* Mobile adjustments */
@media screen and (max-width: 768px) {
  .home-body {
    padding-bottom: 1vh;
  }

  .home-team-images {
    display: none;
  }

  .home-team-poster {
    width: 90%;
  }

  .home-video-player {
    width: 100%;
    height: 25vh;
  }

  .home-teaser-text {
    font-size: calc(3px + 3vmin);
    width: 90%;
  }

  .home-body-mobile-images {
    display: block;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .home-team-image-logo {
    height: 14%;
    width: 14%;
  }

  .home-team-image-logo:not(:last-child) {
    margin-right: 12px;
  }
}