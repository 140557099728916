@import '../../../common/colors.scss';

.profile-card {
  min-height: 40vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0 50px 0;
}

.profile-images {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.profile-image {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 45vh;
  height: auto;
}

.profile-image:not(:last-child) {
  padding-right: 15vh;
}

.profile-container {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-bottom: 30px;
  width: 60%;
}

.profile-name {
  font-family: sans-serif;
  font-style: italic;
  font-size: calc(30px + 2vmin);
  padding-bottom: 8px;
  color: $secondary-text-color
}

.profile-role {
  font-family: sans-serif;
  font-style: italic;
  font-weight: bold;
  font-size: calc(5px + 3vmin);
  padding-bottom: 10px;
  color: $thirdary-text-color;
}

.profile-text {
  font-family: sans-serif;
  font-size: calc(2px + 3vmin);
}

.profile-container::after, .row::after {
  content: "";
  clear: both;
  display: table;
}

/* Mobile adjustments */
@media screen and (max-width: 768px) {
  .profile-name {
    font-size: calc(30px + 1vmin);
  }
  
  .profile-image {
    width: 20vh;
    height: auto;
  }

  .profile-image:not(:last-child) {
    padding-right: 1vh;
  }

  .profile-role {
    font-size: calc(5px + 4vmin);
  }

  .profile-container {
    padding: 8px;
    width: 90%;
  }
}
  