@import '../../common/colors.scss';

@font-face {
  font-family: 'Honk';
  src: url('../../common/VT323-Regular.ttf') format('truetype');
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: $primary-background-color;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 30px;
}

.footer-contact-button {
  font-family: 'Honk';
  text-decoration: none;
  color: $black-text-color;
  border-width: 4px;
  border-style: solid;
  border-color: $thirdary-text-color;
  padding-left: 15px;
  padding-right: 15px;
  background-color: $secondary-text-color;
  font-size: 3rem;
  margin-right: 100px;
  cursor: pointer;
  transition: color 0.2s ease;
  animation: blink 1s infinite alternate;
}

.footer-newsletter-button {
  font-family: 'Honk';
  text-decoration: none;
  color: $black-text-color;
  border-width: 4px;
  border-style: solid;
  border-color: $thirdary-text-color;
  padding-left: 15px;
  padding-right: 15px;
  background-color: $secondary-text-color;
  font-size: 2.8rem;
  margin-left: 100px;
  cursor: pointer;
  transition: color 0.2s ease;
  animation: blink 1s infinite alternate;
}

.footer-pbh-logo {
  width: 6%;
  height: 6%;
}

/* Adjust banner size for mobile */
@media screen and (max-width: 768px) {
  .footer {
    width: 100%;
    max-width: 100%;
    padding-top: 0;
  }

  .footer-contact-button {
    margin: 0.2rem 0;
    font-size: 2rem;
    padding-left: 5px;
    padding-right: 5px;
    margin-right: 20px;
  }

  .footer-newsletter-button {
    margin: 0.2rem 0;
    font-size: 1.8rem;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 20px;
  }

  .footer-pbh-logo{
    width: 18%;
    height: 18%;
  }
}