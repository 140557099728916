@import '../../common/colors.scss';

.teams {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: $primary-background-color;
}

.teams-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $primary-background-color;
  color: $primary-text-color;
  width: 100%;
}

.teams-header {
  margin: 0px;
  font-size: 4rem;
  font-weight: bold;
  text-decoration: underline;
  color: $secondary-text-color;
  padding-bottom: 3vh;
}
  

/* Mobile adjustments */
@media screen and (max-width: 768px) {
  .teams-header {
    margin: 0px;
    font-size: 3rem;
  }
}