@import '../../common/colors.scss';

.banner {
  background-color: $primary-background-color;
}

/* Adjust banner size for mobile */
@media screen and (max-width: 768px) {
  .banner {
    width: 100%;
    max-width: 100%;
  }
}