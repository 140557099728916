@import '../../common/colors.scss';

.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: $primary-background-color;
}

.about-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $primary-background-color;
  color: $primary-text-color;
  width: 100%;
}

.about-text {
  font-size: 1.5rem;
  padding-left: 15%;
  padding-right: 15%;
  background-color: $primary-background-color;
  color: $primary-text-color;
}

.about-header {
  margin: 0px;
  font-size: 4rem;
  font-weight: bold;
  text-decoration: underline;
  color: $secondary-text-color;
}

.profiles {
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

/* Mobile adjustments */
@media screen and (max-width: 768px) {
  .about-text {
    font-size: 1rem;
    padding-left: 5%;
    padding-right: 5%;
  }

  .about-header {
    margin: 0px;
    font-size: 2.5rem;
  }
}
  